import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import TwoColumnSectionWithImage from "../../../components/custom-widgets/two-column-section-with-image";
import Button from "../../../components/custom-widgets/button";
import List from "../../../components/custom-widgets/list";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

//helper functions
import showMktoForm from "../../../helpers/showMktoForm";

const Receivables = () => {
  const title = "Commercial Receivables";
  const description =
    "Learn about commercial receivables at WaFd Bank including ACH, remote deposit, mobile deposit, automated receivables & more to reduce costs and save time.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/receivables/hero-receivables-02-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/receivables/hero-receivables-02-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/receivables/hero-receivables-02-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/receivables/hero-receivables-02-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/receivables/hero-receivables-02-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/receivables/hero-receivables-02-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/receivables/hero-receivables-02-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: "Receivables"
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services/receivables"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-receivables-02-250.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "savings-account-hero",
    chevronBgClass: "bg-info",
    ...getHeroImgVariables(imgData),
    altText: "Woman working on laptop in an office",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Receivable Services to Meet Your Needs"
          }
        },
        {
          id: 2,
          button: {
            id: "receivables-hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/treasury-management-services",
      title: "Treasury Management"
    },
    {
      id: 3,
      active: true,
      title: "Receivables"
    }
  ];

  const FeaturesContent = () => {
    return (
      <>
        <h2>Process Receivables Your Way</h2>
        <h5 className="mb-0 text-success font-weight-bold">ACH</h5>
        <p>
          Receive payments from customers on your schedule by debiting customer accounts through the Automated Clearing
          House. This is a great option for large payments as well as recurring payments.
        </p>
        <h5 className="mb-0 text-success font-weight-bold">Remote Deposit</h5>
        <p>
          No time to drive to the bank? Make deposits from your desk. Other benefits of remote deposit include access to
          deposit details and images of checks deposited, access to multiple accounts and multiple processing times
          means you will see your deposits sooner.
        </p>
        <h5 className="mb-0 text-success font-weight-bold">Mobile Deposit</h5>
        <p>Deposit checks from your phone, when and where you want to, using our WAFD Treasury Mobile app.</p>
        <h5 className="mb-0 text-success font-weight-bold">Lockbox</h5>
        <p>
          Lockbox creates efficiency by automating the receipt, processing and posting of payments. We offer a suite of
          lockbox solutions for all industries.
        </p>
        <Button
          id={"features-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  const benefitsListData = {
    accountName: "heartland-restaurant-list",
    items: [
      {
        id: 1,
        text: "Reduced AR collection time"
      },
      {
        id: 2,
        text: "Reduced costs"
      },
      {
        id: 3,
        text: "Save time and improve efficiency"
      },
      {
        id: 4,
        text: "Additional security with Dual Approval"
      }
    ]
  };

  const BenefitsContent = () => {
    return (
      <>
        <h2>Benefits of WaFd Bank's receivables service include:</h2>
        <List {...benefitsListData} />
        <Button
          id={"benefits-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Receivables</h1>
        <h3 className="mb-0">
          WaFd Bank offers a variety of receivable options to meet your needs. Schedule, track and report funds
          receipts, expediting the collection of funds.
        </h3>
      </section>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../../images/thumbnail-receivables-031124.jpg"
              alt="A businessman in a wheelchair working on a laptop."
              placeholder="blurred"
              quality="100"
              className="border-radius-12 mb-3"
            />
          ),
          content: <FeaturesContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: (
            <StaticImage
              src="../../../images/thumbnail-benefits-of-wafd-receivables-031124.jpg"
              alt="A businesswoman relaxing with her hands behind her head."
              placeholder="blurred"
              quality="100"
              className="border-radius-12 mb-3"
            />
          ),
          content: <BenefitsContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default Receivables;
